/***************************************************************************************
 *
 *** THIS FILE CONTAINS STATIC STATUS AND ACTIONS THAT SHOULB BE COMMON IN EVERYWHERE ***
 *
 ****************************************************************************************/
// get all existing application type
const getApplicationType = () => {
  const allStaticType = [
    {
      app_type_id: 1,
      app_type: "Loan",
    },
    {
      app_type_id: 2,
      app_type: "Card",
    },
    {
      app_type_id: 3,
      app_type: "Merchant",
    },
    {
      app_type_id: 4,
      app_type: "CASE",
    },
  ];

  return allStaticType;
};

// get in station/out station option
const getStationOption = () => {
  const stationOptions = [
    {
      label: "IN_STATION",
      value: "IN_STATION",
    },
    {
      label: "OUT_STATION",
      value: "OUT_STATION",
    },
    {
      label: "SUB_URBAN",
      value: "SUB_URBAN",
    },
    {
      label: "MANAGEABLE",
      value: "MANAGEABLE"
    }
  ];

  return stationOptions;
};

// get in station/out station option
const getUrgencyOption = () => {
  const options = [
    {
      label: "Regular",
      value: 0,
    },
    {
      label: "Urgent",
      value: 1,
    },
  ];

  return options;
};

// get all existing trip list
const getStaticTripTypeList = () => [
  { id: 1, value: "HOUSE", label: "House" },
  { id: 2, value: "OFFICE", label: "Office" },
  { id: 3, value: "GUARANTOR_HOUSE", label: "Guarantor House" },
  { id: 4, value: "GUARANTOR_OFFICE", label: "Guarantor Office" },
  { id: 5, value: "BANK", label: "Bank" },
  { id: 6, value: "DOCUMENT", label: "Document" },
  { id: 7, value: "RENTAL", label: "Rental" },
  { id: 8, value: "CAR_QUATATION", label: "Car Quatation" },
  { id: 9, value: "PROPERTY", label: "Property" },
  // { id: 10, value: "LAND", label: "Land" },
];

// get trip type by receiving concern type as parameter
const getTripTypeByConcernType = (concernType) => {
  if (!concernType) {
    return [];
  }
  const concernTypeToUpperCase = concernType?.toUpperCase();

  switch (concernTypeToUpperCase) {
    case "APPLICANT":
      return [
        { id: 1, value: "HOUSE", label: "House" },
        { id: 2, value: "OFFICE", label: "Office" },
        { id: 3, value: "BANK", label: "Bank" },
        { id: 4, value: "DOCUMENT", label: "Document" },
        { id: 5, value: "RENTAL", label: "Rental" },
        { id: 6, value: "CAR_QUATATION", label: "Car Quatation" },
        { id: 7, value: "PROPERTY", label: "Property" },
        // { id: 8, value: "LAND", label: "Land" },
      ];
    case "CO_APPLICANT":
      return [
        { id: 1, value: "HOUSE", label: "House" },
        { id: 2, value: "OFFICE", label: "Office" },
        { id: 3, value: "BANK", label: "Bank" },
        { id: 4, value: "DOCUMENT", label: "Document" },
        { id: 5, value: "RENTAL", label: "Rental" },
        { id: 6, value: "CAR_QUATATION", label: "Car Quatation" },
        { id: 7, value: "PROPERTY", label: "Property" },
        // { id: 8, value: "LAND", label: "Land" },
      ];
    case "GUARANTOR":
      return [
        { id: 1, value: "GUARANTOR_HOUSE", label: "Guarantor House" },
        { id: 2, value: "GUARANTOR_OFFICE", label: "Guarantor Office" },
        { id: 3, value: "BANK", label: "Bank" },
        { id: 4, value: "DOCUMENT", label: "Document" },
        { id: 5, value: "RENTAL", label: "Rental" },
        { id: 6, value: "CAR_QUATATION", label: "Car Quatation" },
        { id: 7, value: "PROPERTY", label: "Property" },
        // { id: 8, value: "LAND", label: "Land" },
      ];
    default:
      return [{ id: 1, value: "N/A", label: "n/a" }];
  }
};

// get all existing trip type
const getTripTypeList = () => {
  return [
    { id: 1, value: "HOUSE", label: "House" },
    { id: 2, value: "OFFICE", label: "Office" },
    { id: 3, value: "GUARANTOR_HOUSE", label: "Guarantor House" },
    { id: 4, value: "GUARANTOR_OFFICE", label: "Guarantor Office" },
    { id: 5, value: "BANK", label: "Bank" },
    { id: 6, value: "DOCUMENT", label: "Document" },
    { id: 7, value: "RENTAL", label: "Rental" },
    { id: 8, value: "CAR_QUATATION", label: "Car Quatation" },
    { id: 9, value: "PROPERTY", label: "Property" },
    // { id: 10, value: "LAND", label: "Land" },
  ];
};

export {
  getTripTypeByConcernType,
  getTripTypeList,
  getApplicationType,
  getStationOption,
  getUrgencyOption,
  getStaticTripTypeList,
};
