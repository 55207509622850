import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Import Components
import { Modal, Input, Select } from 'antd'

// Import Actions, and Reducers
import { updateStation } from '../../../Services/Actions/applicationActions'
import { showAlert } from '../../../Services/Actions/commonActions'
import { getOrganizationStation } from '../../../Services/Actions/adminPanelActions'

const StationUpdateModal = ({ isStationUpdateModalOpen, onStationUpdateModalClose }) => {
    const dispatch = useDispatch()
    const {applicationDetails, isUpdateStationLoading } = useSelector(state => state.application)
    const [ station, setStation ] = useState('')
    const [ stationOptions, setStationOptions ] = useState([])
    console.log('applicationDetails', applicationDetails);
    useEffect(() => {
        // Get Selected Organization Station Options
        dispatch(getOrganizationStation({
            org_id: applicationDetails?.group_uuid,
            application_type: applicationDetails?.application_type
        }))
            .then(result => {
                setStationOptions(result)
            })
        
        // Set File No
        setStation(applicationDetails?.station)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationDetails])

    const handleOnChange = e => {
        setStation(e)
    }

    const handleStationUpdate = () => {
        // Check if file no is empty
        if (station === '' || !station) {
            // Show alert
            showAlert('error', 'Station. cannot be empty.')
            return
        }

        // Check if file no is same as applicationDetails?.application_id
        else if (station === applicationDetails?.station) {
            // Show alert
            showAlert('error', `Station: ${station} is already set.`)
            return
        }

        // Create payload
        const formData = new FormData()
        formData.append('_method', 'PUT')
        formData.append('station', station)

        // Update station
        dispatch(updateStation(applicationDetails, formData)).then((e) => {
            // console.log('station', e);
            if(e==='success'){
                onStationUpdateModalClose()
            }
        })

    }

    return (
        <Modal
            title={'Update Station'}
            visible={isStationUpdateModalOpen}
            maskClosable={false}
            okText={'Update'}
            cancelText={'Cancel'}
            okButtonProps={{ loading: isUpdateStationLoading }}
            onCancel={onStationUpdateModalClose}
            onOk={handleStationUpdate}
        >
           
            <Select
                style={{ width: '100%' }}
                placeholder={'Select an option'}
                onChange={handleOnChange}
                value={station}
            >
                {
                    stationOptions.map((item) => (
                        <Select.Option key={item?.key} value={item?.value}>{item?.label}</Select.Option>
                    ))
                }
            </Select>
        </Modal>
    )
}



export default StationUpdateModal