import axios from "axios"
import { VERIFIER } from '../../Config/config'

// import actions and reducers
import { setRouteDistance, setVisitedPathData } from '../Reducers/routeStatisticsReducer'
import { setIsDateOrgFilterLoading } from '../Reducers/commonReducer'
import { showAlert } from "./commonActions"


// transforming verifier route data into deckgl triplayer format
const transformInTripLayerFormat = (rawData) => {
    const triplayerDataTemplate = [
        {
            waypoints: []
        }
    ]

    // generating data for deckgl trip layer
    rawData?.coordinates?.forEach(item => {
        triplayerDataTemplate[0]?.waypoints.push({ coordinates: item })
    })
    return triplayerDataTemplate
}

// get visited path of individual verifier
const getVerifierVisitedPath = (params) => {
    return async dispatch => {
        const currentTime = new Date().toTimeString().split(' ')[0]
        dispatch(setIsDateOrgFilterLoading(true))

        axios.get(`${VERIFIER.VISITED_ROUTE}?user_id=${params.agent_id}&dateFrom=${params.dateFrom}+00:00:00&dateTill=${params.dateTill}+${currentTime}&path=true`)
            .then(res => {
                // console.log(res, 'res')
                if (res?.status === 200 && res?.data?.data?.path?.length > 0) {
                    dispatch(setRouteDistance(`${Number.parseFloat(res?.data?.data?.distance).toFixed(2)} km`))
                    const parsedData = JSON.parse(res?.data?.data?.path)

                    return parsedData

                }
                dispatch(setIsDateOrgFilterLoading(false))
                return []
            })
            .then(res => {
                if (res) {
                    // transform call to format data into waypoints format for deckgl trip layer
                    const formattedData = transformInTripLayerFormat(res)
                    dispatch(dispatch => {
                        dispatch(setVisitedPathData(formattedData))
                        dispatch(setIsDateOrgFilterLoading(false))
                    }
                    )
                }
                dispatch(setIsDateOrgFilterLoading(false))
                return []
            })
            .catch(err => {
                dispatch(setIsDateOrgFilterLoading(false))
                dispatch(setRouteDistance(0))
                dispatch(setVisitedPathData([]))
                showAlert('error', 'No Points Found')
                console.error(err)
            })
    }
}

export { getVerifierVisitedPath }