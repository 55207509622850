import React, { useEffect, useState } from 'react';

// Import Actions, Methods, & Reducers
import { useDispatch, useSelector } from 'react-redux';
import { getTripTypeList } from '../../../Services/Actions/staticOptionAction'

// Import Icons
import { DownloadOutlined, RetweetOutlined } from '@ant-design/icons';

// Import Components
import { Button, Col, Form, InputNumber, Popconfirm, Row, Select, Table, Tag, Typography } from 'antd';
import { assignQuesToOrganization, fetchTripTypeWiseQuestionList, unAssignQuesFromOrganization } from '../../../Services/Actions/adminPanelActions';
import { showAlert } from '../../../Services/Actions/commonActions';

// Import Constants
const { Title } = Typography;
const { Option } = Select
const XLSX = require('xlsx')

const QuestionList = () => {
  // Get Data From Redux
  const { tripWiseQuestionList, subtypeOptions, appTypeOptions } = useSelector(state => state.adminPanel);
  const selectedOrgId = useSelector(state => state.adminPanel.selectedOrgId ?? null)

  // States
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [questionOnState, setQuestionOnState] = useState([])
  const [allTripTypeList, setAllTripTypeList] = useState()
  const [selectedApplicationType, setSelectedApplicationType] = useState('')
  const [selectedSubTypeId, setSelectedSubTypeId] = useState('')
  const [selectedTripType, setSelectedTripType] = useState('')

  // Define Table Columns
  const columns = [
    {
      title: 'Question',
      dataIndex: 'question',
      width: '50%'
    },
    {
      title: 'Field Type',
      dataIndex: 'field_type',
      align: 'center',
      render: (text) => (
        <div style={{ textAlign: 'center' }}>
          <Tag
            color={
              text === 'MCQ' ? '#4cc4b2' :
                text === 'SCQ' ? '#052e16' :
                  '#969fff'}
          >
            {text}
          </Tag>
        </div>
      ),
    },
    {
      title: 'Trip Type',
      dataIndex: 'trip_type',
      width: '18%',
      align: 'center',
      render: (text) => {
        if (text) {
          return (
            <div style={{ textAlign: 'center' }}>
              <Tag
                color='green'
              >
                {text}
              </Tag>
            </div>
          )
        } else return null
      }
    },
    {
      title: 'Sort Order',
      dataIndex: 'sort_order',
      width: '11%',
      align: 'center',
      render: (sortOrder, question) => (
        <InputNumber
          min={0}
          size='small'
          value={sortOrder}
          onChange={(value) => _handleSortOrderChange(question.key, value)}
          placeholder='Sort Order'
          style={{ width: "90px" }}
        />
      ),
    },
    {
      title: 'Action',
      dataIndex: '',
      width: '8%',
      align: 'center',
      render: (_, question) => (
        <Popconfirm
          placement="topLeft"
          title={'Are you sure to unassign this question?'}
          description={'Unassign the question'}
          okText="Yes"
          cancelText="No"
          onConfirm={() => _handleRemoveQuestion(question)}
        >
          <Button size='small' danger>Remove</Button>
        </Popconfirm>
      )
    }
  ];

  // Handle Remove Question
  const _handleRemoveQuestion = (question) => {
    const data = {
      group_id: selectedOrgId,
      group_subtype_id: selectedApplicationType, // fetch again
      trip_type: selectedTripType,
      sub_type_id: selectedSubTypeId,
      questions: [question.id]
    }
    dispatch(unAssignQuesFromOrganization(data))
  }

  // Binary String To Array Buffer
  const binaryStringToArrayBuffer = (binaryString) => {
    const buffer = new ArrayBuffer(binaryString.length);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < binaryString.length; i++) {
      view[i] = binaryString.charCodeAt(i) & 0xFF;
    }
    return buffer;
  }

  // Handle Download Excel
  const _handleDownloadExcel = () => {

    if (!selectedSubTypeId) {
      showAlert('error', 'Plaese Selecte Subtype')
    }

    // Find Sub Type Name
    const selectedSubType = subtypeOptions.find((subType) => subType.app_subtype_id === selectedSubTypeId)

    // Check Sort Order
    const missingOrder = questionOnState.find(question => {
      return typeof question.sort_order === 'object'
    })

    // Validate Sort Order
    if (missingOrder && Object.keys(missingOrder).length > 0) {
      showAlert('error', 'Please Set Order')
    }

    // Validate Sub Type Id
    if (!selectedSubTypeId) {
      showAlert('error', 'Please Set Sub Type')
    }

    // Validate Trip Tyoe
    if (!selectedTripType) {
      showAlert('error', 'Please Set Trip Type')
    }

    if (typeof missingOrder === 'undefined' && selectedSubTypeId && selectedTripType) {

      // Sorting By Number
      const sortedData = questionOnState.sort((a, b) => a.sort_order - b.sort_order)

      const normalizeData = []
      sortedData.forEach((data, index) => {
        normalizeData.push({
          SL: index + 1,
          Question: data.question,
          'Field Type': data.field_type
        })
      })

      // Define Workbook & Worksheet
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(normalizeData);
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

      // Generate a data array for the Blob
      const blobData = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

      // Convert the binary string to a Blob
      const blob = new Blob([binaryStringToArrayBuffer(blobData)], { type: 'application/octet-stream' });

      // Create a URL for the Blob data
      const url = window.URL.createObjectURL(blob);

      // Create a download link and trigger the download
      const a = document.createElement('a');
      a.href = url;
      a.download = `${selectedSubType.app_subtype}_${selectedTripType}_Questions.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  // Handle Update Question Sort Order
  const _handleUpdateOrder = () => {

    const modifiedQuestion = []
    questionOnState?.forEach(question => {
      modifiedQuestion.push({
        id: question.id,
        question: question.question,
        is_required: question.is_required,
        sort_order: question.sort_order
      })
    })

    const modifiedObject = {
      group_id: selectedOrgId,
      trip_type: selectedTripType,
      sub_type_id: selectedSubTypeId,
      questions: modifiedQuestion
    }


    // Check Sort Order
    const missingOrder = modifiedQuestion.find(question => {
      return typeof question.sort_order === 'object'
    })

    // Validate Missing Order
    if (missingOrder && Object.keys(missingOrder).length > 0) {
      showAlert('error', 'Please Set Order')
    }

    // Validate Sub Type Id
    if (!selectedSubTypeId) {
      showAlert('error', 'Please Set Sub Type')
    }

    // Validate Trip Type
    if (!selectedTripType) {
      showAlert('error', 'Please Set Trip Type')
    }

    // If All Okay
    if (typeof missingOrder === 'undefined' && selectedSubTypeId && selectedTripType) {
      dispatch(assignQuesToOrganization(modifiedObject))
    }
  }

  // Handle Sort Order
  const _handleSortOrderChange = (indexKey, sortOrder) => {

    const updatedSelectedQuestions = questionOnState.map((question) => {
      if (question.key === indexKey) {
        return { ...question, sort_order: sortOrder };
      }
      return question;
    });

    setQuestionOnState(updatedSelectedQuestions);
  };

  // Update Type Options and Set Subtype with Prefix
  const _onChangeApplicationType = (values) => {
    const result = []
    if (subtypeOptions?.length > 0) {
      subtypeOptions.forEach((item, index) => {
        if (item?.app_type_id === values) {
          return result.push({ ...item, id: index })
        }
      })
    }

    setSelectedApplicationType(values)

    // Clear Trip Type
    form.setFieldsValue({
      trip_type: null
    })
    setSelectedTripType('')
    setSelectedSubTypeId('')
  }

  // Fetching Trip Wise Question List
  const _onChangeSubType = (sub_type_id) => {
    const params = {
      group_subtype_id: sub_type_id, // selectedApplicationType
      org_id: selectedOrgId,
      trip_type: ''
    }

    dispatch(fetchTripTypeWiseQuestionList(params))
    setSelectedSubTypeId(sub_type_id)

    // Clear Trip Type
    form.setFieldsValue({
      trip_type: null
    })
    setSelectedTripType('')
  }

  const _onChangeTripType = (value) => {
    const params = {
      group_subtype_id: selectedSubTypeId,
      org_id: selectedOrgId,
      trip_type: value
    }

    dispatch(fetchTripTypeWiseQuestionList(params))
    setSelectedTripType(value)
  }


  // Get and Set All Trip List
  useEffect(() => {
    const allTripType = getTripTypeList()
    setAllTripTypeList(allTripType)
  }, [])

  // Trip Wise Question List
  useEffect(() => {
    // setQuestionOnState(() => [])
    const data = []
    tripWiseQuestionList.forEach((tripData, index) => {
      data.push(
        {
          ...tripData,
          key: index,
          question_key: tripData.key,
        }
      )
    })
    // console.log(data)
    // Sort By sort_order
    data.sort((a, b) => a.sort_order - b.sort_order)
    setQuestionOnState(() => data)
  }, [tripWiseQuestionList])

  // Clear Form States
  useEffect(() => {
    form.setFieldsValue({
      type: null,
      sub_type: null,
      trip_type: null
    })
    setQuestionOnState(() => [])
    setSelectedApplicationType(() => '')
    setSelectedTripType(() => '')
    setSelectedSubTypeId(() => '')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrgId])
  // console.log(questionOnState, 'questionOnState')
  // console.log(tripWiseQuestionList, 'tripWiseQuestionList')
  // console.log(selectedSubTypeId, 'selectedSubTypeId')
  return (
    <div style={{ background: "#F6F6F6", padding: "24px 42px" }}>
      <Row style={{ background: "white", padding: "12px 24px" }}>
        <Col span={24}>
          <Form form={form} style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Title level={4} style={{ color: '#0067B1' }}>Trip Specific Question</Title>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {/* Type */}
              <Form.Item name='type' style={{ width: '150px' }}>
                <Select
                  showSearch
                  placeholder='Select Type'
                  optionFilterProp="children"
                  onChange={(value) => _onChangeApplicationType(value)}
                >
                  {
                    appTypeOptions?.map(item => (
                      <Select.Option
                        key={item?.app_type_id}
                        value={item?.app_type_id}
                      >
                        {item?.label}
                      </Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>

              {/* Subtype */}
              <Form.Item name='sub_type' style={{ width: '150px', marginLeft: '20px' }}>
                <Select
                  placeholder='Select Subtype'
                  optionFilterProp="children"
                  onChange={(value) => _onChangeSubType(value)}
                >
                  {
                    // eslint-disable-next-line array-callback-return
                    subtypeOptions?.length > 0 && subtypeOptions.map((item, index) => {
                      if (item?.app_type_id === selectedApplicationType) {
                        return (
                          <Select.Option key={index + 1} value={item?.app_subtype_id}>
                            {item?.app_subtype}
                          </Select.Option>
                        )
                      }
                    })
                  }
                </Select>
              </Form.Item>

              {/* Trip */}
              <Form.Item name='trip_type' style={{ width: '150px', marginLeft: '20px' }}>
                <Select
                  placeholder='Select Trip'
                  onChange={(value) => _onChangeTripType(value)}
                >
                  {
                    allTripTypeList?.length > 0 && allTripTypeList.map((item, index) => {
                      return (
                        <Option key={index + 1} value={item.value}>
                          {item.label}
                        </Option>
                      )
                    })
                  }
                </Select>
              </Form.Item>
            </div>
          </Form>

          {/* Question List */}
          <Row>
            <Table
              bordered
              showHeader={true}
              pagination={false}
              style={{ width: '100%', border: '1px solid #d9d9d9' }}
              columns={columns}
              dataSource={questionOnState}
              scroll={{ y: 300 }}
            />
          </Row>
        </Col>
      </Row>
      <Row justify='end' style={{ marginTop: '10px', gap: '15px' }}>
        <Button icon={<RetweetOutlined />} type='primary' onClick={_handleUpdateOrder}>Update Order</Button>
        <Button icon={<DownloadOutlined />} type='primary' onClick={_handleDownloadExcel} >Export</Button>
      </Row>
    </div>
  );
};

export default QuestionList;
